import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Config from "../../../helpers/Config";
import BannerSlider from "../../components/home/BannerSlider";
import NewProducts from "../../components/products/NewProducts";
import PopularProducts from "../../components/products/PopularProducts";
import CompaignSection from "../../components/shared/CompaignSection";
import ContactBanner from "../../components/shared/ContactBanner";
import CustomerTestimonial from "../../components/shared/CustomerTestimonial";
import DiscountBannerOmg from "../../components/shared/DiscountBannerOmg";
import PopularCategories from "../../components/shared/PopularCategories";

const Home = () => {
  const navigate = useNavigate();
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Home</title>
        <meta name="description" content={siteTitle + " - Home"} />
        <meta name="keywords" content="Home"></meta>
      </Helmet>

      <div className="bg-light">
        <BannerSlider />
        <PopularCategories />
        <DiscountBannerOmg />
        <NewProducts effect="icon-inline" />
        <CompaignSection />
        {/* <TodayHotDeal /> */}
        <CustomerTestimonial />
        <PopularProducts hoverEffect="icon-inline" />
        <ContactBanner />
      </div>
    </>
  );
};

export default Home;
